import Head from 'next/head'
import dynamic from 'next/dynamic'

const LoginLayout = dynamic(() => import('@/layouts/Login'), {
  ssr: false
})

const LoginForm = dynamic(() => import('@/components/LoginForm'), {
  ssr: false
})

const PageLoginForm = () => (
  <>
    <Head>
      <title>Login &mdash; Suggestic</title>
    </Head>
    <LoginLayout theme="blue" header="Login">
      <LoginForm />
    </LoginLayout>
  </>
)

export default PageLoginForm
